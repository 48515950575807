<template>
  <v-card class="mt-3">
    <v-card-title class="primary lighten-1">
      <v-toolbar-title class="white--text h3">
        {{ detail.payment_gateway_name }}
      </v-toolbar-title>
    </v-card-title>
    <v-card-text class="py-3">
      <p>
        <strong>countries :</strong>
        <span>
          <v-chip
            class="ma-1"
            small
            v-for="(country, key) in detail.countries"
            :key="key"
          >
            {{ country.title }}
          </v-chip>
        </span>
      </p>
      <div v-if="detail.credentials_test">
        <p>
          <strong>PAYPAL_CLIENT_ID:</strong>
          {{ detail.credentials_test.PAYPAL_CLIENT_ID }}
        </p>
        <p>
          <strong>PAYPAL_SECRET:</strong>
          {{ detail.credentials_test.PAYPAL_SECRET }}
        </p>
      </div>
      <div v-if="detail.credentials_prod">
        <p>
          <strong>PAYPAL_CLIENT_ID:</strong>
          {{ detail.credentials_prod.PAYPAL_CLIENT_ID }}
        </p>
        <p>
          <strong>PAYPAL_SECRET:</strong>
          {{ detail.credentials_prod.PAYPAL_SECRET }}
        </p>
      </div>

      <v-divider></v-divider>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ShowDetailItem",
  props: ["detail"],
};
</script>
